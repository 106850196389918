import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { lighten } from "polished";

const SubmitButton = ({ isValid, primary, text }) => (
	<Button type="submit" disabled={!isValid} primary={primary}>
		{text ? text : "Send"}
	</Button>
);

const Button = styled.button`
	height: 60px;
	background: ${({ theme, primary }) => primary ? theme.colors.gold.primary : theme.colors.grey.dark};
	color: ${({ theme, primary }) => primary ? "#000" : theme.colors.gold.primary};
	border: 0;
	cursor: pointer;
	pointer-events: all;

	&:disabled {
		color: #666;
		pointer-events: none;
	}

	&:focus {
		outline: 0;
	}

	&:hover {
		background: ${({ theme, primary }) => primary ? lighten(0.05, theme.colors.gold.primary) : theme.colors.gold.primary};
		color: #000;
	}
`;

SubmitButton.propTypes = {
	isValid: PropTypes.bool,
	text: PropTypes.string,
	primary: PropTypes.bool
};

export default SubmitButton;
