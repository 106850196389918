import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { lighten } from "polished";

import checkmark from "@common/website/assets/images/checkmark.svg";

const FormField = ({ fieldName, type, width, name, label, placeholder, value, touched, error, onChange, onBlur }) => {
	const [showMore, setShowMore] = useState(false);
	return (
		<>
			<Container hasLabel={label} type={type} fieldWidth={width} fieldName={fieldName}>
				<Input
					id={fieldName}
					as={fieldName === "comment" ? "textarea" : "input"}
					type={type}
					name={name}
					placeholder={placeholder}
					autoComplete={fieldName === "comment" ? "none" : fieldName}
					{...(type === "checkbox" || type === "radio" ? { checked: value } : { value: value })}
					onChange={onChange}
					onBlur={onBlur}
				/>
				{label ? (
					<>
						<Label type={type} htmlFor={fieldName}>
							{label}{" "}
						</Label>
						{fieldName === "financing" ? (
							<ShowMoreLink onClick={() => setShowMore(!showMore)}>Mer informasjon</ShowMoreLink>
						) : null}
					</>
				) : null}
				{error && touched ? <Error>{error}</Error> : null}
			</Container>
			{fieldName === "financing" && showMore ? (
				<More>
					<p>
						Det er ikke uvanlig at boligen du eier i dag skal selges når du nå kjøper ny. I den sammenheng kan det være
						nyttig å få en grundig vurdering av hva boligen er verdt. Da får du et godt bilde av hva du har råd til å
						bruke på din nye bolig.
					</p>
					<p>
						Vi ønsker gjerne å yte deg denne servicen. Kryss av for at du ønsker verdivurdering, så vil en av våre
						dyktige meglere kontakte deg for en uforpliktende vurdering.
					</p>
					<p>
						Vår megler kan også gi deg nyttige råd knyttet til ditt boligkjøp. Ønsker du en gjennomgang av dagens
						marked? Vi deler gjerne vår kunnskap med deg!
					</p>
					<p>
						Har du først en av våre meglere på besøk anbefaler vi at dere gjennomgår salgsfremmende tiltak som kan
						gjøres i din bolig innen den skal selges. Når du er ute i god tid med vurderingen kan du forberede
						boligsalget grundig.
					</p>
					<p>La en av våre meglere bli din rådgiver i kjøps- og salgsprosessen.</p>
					<p>Vi gleder oss til å høre fra deg!</p>
				</More>
			) : null}
		</>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	flex-flow: ${({ type }) => (type === "checkbox" ? "row nowrap" : "column")};
	align-items: ${({ type }) => (type === "checkbox" ? "center" : "flex-start")};
	width: ${({ fieldWidth, type, fieldName }) =>
		fieldWidth
			? fieldWidth === "100%"
				? "100%"
				: `calc(${fieldWidth} - 6px)`
			: type === "checkbox" || fieldName === "comment"
			? "100%"
			: "calc(50% - 6px)"};
	margin: 0 0 12px 0;

	label {
		margin: ${({ type }) => (type === "checkbox" ? "0 0 0 6px" : "0 0 12px 0")};
	}

	input[type="checkbox"]  {
		position: absolute;
		left: -999px;
	}

	input[type="text"] {
		width: 100%;
	}

	textarea {
		width: 100%;
		min-height: 192px;
		padding: 18px;
	}

	@media screen and (max-width: 960px) {
		flex-flow: row wrap;
		width: 100%;
	}
`;

const Label = styled.label`
	${({ type }) =>
		type === "checkbox" &&
		css`
			position: relative;
			display: flex;
			align-items: center;
			&:before {
				content: "";
				display: inline-flex;
				width: 24px;
				height: 24px;
				min-width: 24px;
				min-height: 24px;
				margin-right: 18px;
				background: #595b5b;
			}

			@media screen and (max-width: 960px) {
				font-size: 14px;
			}
		`}
`;

const ShowMoreLink = styled.a`
	color: ${({ theme }) => theme.colors.gold.primary};
	margin-left: 12px;
	margin-top: 4px;
	font-size: 12px;

	@media screen and (max-width: 960px) {
		margin: 4px 0 0 48px;
	}
`;

const Input = styled.input`
	background: ${({ theme }) => theme.colors.grey.darkest};
	height: ${({ type }) => (type === "checkbox" ? "auto" : "60px")};
	width: ${({ type }) => (type === "checkbox" ? "auto" : "100%")};
	padding ${({ type }) => (type === "checkbox" ? "0" : "0 18px")};
	border: 0;
	color: #fff;
	font-size: 24px;

	&:focus {
		outline: 0;
		background: ${({ theme }) => theme.colors.grey.dark};
	}

	&::placeholder {
		color: #fff;
	}

	&:checked + ${Label} {
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 21;
			height: 100%;
			min-width: 24px;
			min-height: 24px;
			margin-right: 18px;
			background-image: url(${checkmark});
			background-position: center;
			background-size: 18px 18px;
			background-repeat: no-repeat;
		}
	}
`;

const More = styled.div`
	display: block;
	padding: 18px;
	margin: 18px 6px;
	background: ${({ theme }) => theme.colors.grey.dark};

	a {
		color: ${({ theme }) => theme.colors.gold.primary};
	}
`;

const Error = styled.div`
	position: absolute;
	top: 6px;
	right: 6px;
	color: ${({ theme }) => theme.colors.gold.primary};
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 500;
`;

FormField.propTypes = {
	fieldName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	width: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
	touched: PropTypes.bool.isRequired,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired
};

export default FormField;
