import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import Context from "@common/website/components/context/shared_utils";

const Status = ({ isSubmitting, status, financingText }) => {
	const sharedUtils = useContext(Context);
	return (
		<Container id="form-sms-prompt">
			<Inner>
				{isSubmitting ? (
					<Spinner>
						<MDSpinner userAgent={sharedUtils.userAgent} size={60} singleColor="#e8c893" />
					</Spinner>
				) : (
					<>
						<Title>Takk for din henvendelse</Title>
						<Text>En av våre eiendomsmeglere tar kontakt om kort tid</Text>
						{financingText ? (
							<Text>
								Du vil om kort tid motta en SMS. Denne må besvares med ordet BEKREFT for å samtykke til at en rådgiver
								fra Nordea kontakter deg på telefonnummeret som du har oppgitt.
							</Text>
						) : null}
					</>
				)}
			</Inner>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	min-height: 100%;
	background: rgba(0, 0, 0, 0.95);
	z-index: 10;
`;

const Inner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100vh;
	text-align: center;
	z-index: 12;
`;

const Spinner = styled.div``;

const Title = styled.h1`
	font-size: 42px;
	line-height: 1.25;
`;

const Text = styled.p`
	max-width: 480px;
	padding: 0 18px;
`;

Status.propTypes = {
	isSubmitting: PropTypes.bool,
	status: PropTypes.any,
	financingText: PropTypes.bool
};

export default Status;
